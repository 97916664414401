import React, { createContext, useContext, useState, PropsWithChildren, useEffect } from 'react'
import { findUploadIndexFromUpload, getMessage } from '../reducers/media.reducers'
import { getImageProxySizes, uploadToSignedUrl } from '../api/modules/media.api'
import { UploadHookForm, UploadState, UploadType } from '../actions/media.actions'
import { socket } from '../socket'
import { merge } from 'lodash'

interface UploadContextProps {
  uploadQueue: UploadHookForm[]
  startUpload: (upload: UploadHookForm) => void
  removeUpload: (upload: UploadHookForm) => void
}

const UploadContext = createContext<UploadContextProps | null>(null)

export const UploadProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [uploadQueue, setUploadQueue] = useState<UploadHookForm[]>([])

  useEffect(() => {
    const hasCompletedUploads = uploadQueue.some(uQ => uQ.state === UploadState.Completed)
    if (hasCompletedUploads) {
      setTimeout(() => {
        const updatedUploadQueue = uploadQueue.filter(uQ => uQ.state !== UploadState.Completed)
        setUploadQueue(updatedUploadQueue)
      }, 1000)
    }
  }, [uploadQueue])

  const startUpload = async (upload: UploadHookForm) => {
    // set the main message
    upload.message = getMessage(upload.state, upload.type, upload.name)
    let localUploadQueue = [...uploadQueue, upload]
    setUploadQueue(localUploadQueue)
    try {
      // upload the file
      const uploadRes = await uploadToSignedUrl(
        upload,
        (progressEvent) => {
          if (progressEvent.total) {
            // set the progress of the upload
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            localUploadQueue = localUploadQueue.map(u => {
              if (u.url === upload.url) {
                u.progress = progress
              }
              return u
            })
            setUploadQueue(localUploadQueue)
          }
        }
      )
      localUploadQueue = localUploadQueue.map(u => {
        if (u.url === upload.url && uploadRes) {
          // construct the location of the file
          const href = uploadRes.href
          u.medium.src = { href }
          u.medium.type = u.type
        }
        return u
      })
      setUploadQueue(localUploadQueue)
    } catch (err) {
      const error = err as Error
      console.error(error)
      localUploadQueue = localUploadQueue.map(u => {
        if (u.url === upload.url) {
          u.state = UploadState.Error
          u.message = "Error uploading, please try again"
        }
        return u
      })
      setUploadQueue(localUploadQueue)
    }

    if ([UploadType.Sound, UploadType.Ambisonic].includes(upload.type)) {
      // sound upload
      try {
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            u.state = UploadState.Processing
            u.message = getMessage(u.state, u.type, u.name)
          }
          return u
        })
        const mediaupload = await processUploadSoundConversion(
          upload,
          (mediaupload) => {
            localUploadQueue = localUploadQueue.map(u => {
              if (u.url === upload.url) {
                u.progress = mediaupload.progress
              }
              return u
            })
            setUploadQueue(localUploadQueue)
          })
        merge(upload.medium, mediaupload)
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            merge(u.medium, mediaupload)
            u.state = UploadState.Completed
            u.progress = mediaupload.progress
            u.message = getMessage(u.state, u.type, u.name)
          }
          return u
        })
        setUploadQueue(localUploadQueue)
        // success - update in form
        upload.setValue(upload.form.fieldNameTarget, upload.medium)
      } catch (err) {
        const error = err as Error
        console.error(error)
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            u.state = UploadState.Error
            // u.message = "Error converting, please try again"
            u.message = getMessage(u.state, u.type, u.name)
          }
          return u
        })
        setUploadQueue(localUploadQueue)
      }
    } else if ([UploadType.Cover, UploadType.Image].includes(upload.type)) {
      // image upload
      try {
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            u.state = UploadState.Processing
            u.message = getMessage(u.state, u.type, u.name)
          }
          return u
        })
        await processUploadImage(upload)
        // success - update in form
        upload.setValue(upload.form.fieldNameTarget, upload.medium)
      } catch (err) {
        const error = err as Error
        console.error(error)
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            u.state = UploadState.Error
            u.message = error.message
          }
          return u
        })
        setUploadQueue(localUploadQueue)
      }
    } else {
      // generic upload
      try {
        // upload the file
        const uploadRes = await uploadToSignedUrl(
          upload,
          (progressEvent) => {
            if (progressEvent.total) {
              // set the progress of the upload
              const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              localUploadQueue = localUploadQueue.map(u => {
                if (u.url === upload.url) {
                  u.state = UploadState.Starting
                  u.progress = progress
                  u.message = getMessage(u.state, u.type, u.name)
                }
                return u
              })
            }
          }
        )
        // construct the location of the file
        const href = uploadRes.href
        // set the href as we're not converting
        upload.medium.href = href
        // set the src so we have the original
        upload.medium.src = { href }
        // set the type
        upload.medium.type = upload.type
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            // @TODO: fix updating for generic upload
            u.state = UploadState.Completed
          }
          return u
        })
        setUploadQueue(localUploadQueue)
        upload.setValue(upload.form.fieldNameTarget, upload.medium)
      } catch (err) {
        const error = err as Error
        console.error(error)
        localUploadQueue = localUploadQueue.map(u => {
          if (u.url === upload.url) {
            u.state = UploadState.Error
            u.message = error.message
          }
          return u
        })
        setUploadQueue(localUploadQueue)
      }
    }

  }

  async function processUploadSoundConversion(upload: UploadHookForm, progress: (mediaupload: IMediaUpload) => void) {
    // if the upload is an audio file, we use sockets to monitor conversion progress
    let localUploadQueue = uploadQueue
    const promise = new Promise<IMediaUpload>((resolve, reject) => {
      socket.on('error', () => {
        // the conversion failed
        reject()
      })
      socket.on('sync', (data) => {
        // we're updating progress from the conversion worker
        const mediaupload = data as IMediaUpload
        progress(mediaupload)
        if (data.state === 'completed') {
          // acknowledge the upload as having succeeded
          resolve(mediaupload)
        }
      })
    })
    socket.emit('create', upload.medium, (data: IMediaUpload) => {
      // the conversion has started
      const mediaupload = data as IMediaUpload
      progress(mediaupload)
    })
    return promise

  }

  async function processUploadImage(upload: UploadHookForm) {
    const { sizes } = await getImageProxySizes(upload.medium)
    upload.medium.sizes = sizes
  }

  const removeUpload = (upload: UploadHookForm) => {
    const i = findUploadIndexFromUpload(uploadQueue, upload)
    if (i > -1) {
      setUploadQueue(uploadQueue.splice(i, 1))
    }
  }


  const value = {
    uploadQueue,
    startUpload,
    removeUpload,
  }

  return (
    <UploadContext.Provider value={value}>
      {children}
    </UploadContext.Provider>
  )
}

export const useUpload = () => {
  const uploadContext = useContext(UploadContext)

  if (!uploadContext) {
    throw new Error(
      "uploadContext has to be used within <UploadContext.Provider>"
    )
  }

  return uploadContext
}
