import { Reducer } from 'redux'
import {
  ADD_UPLOAD,
  UPDATE_UPLOAD,
  REMOVE_UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_MEDIA_SUCCESS,
  REMOVE_MEDIA_UPLOAD,
  UPDATE_MEDIA_UPLOAD,
} from '../constants/media.constants'
import { UploadState } from '../actions/media.actions'

const initialState: IMediaState = {
  uploads: [],
}

const media: Reducer<IMediaState> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_UPLOAD: {
      let uploads = []
      const { upload } = action.payload as { upload: IUploadBase }
      upload.message = getMessage(upload.state, upload.type, upload.name)
      uploads = [...state.uploads, upload]
      return { ...state, uploads }
    }
    case REMOVE_UPLOAD: {
      const { upload } = action.payload as { upload: IUploadBase }
      const i = findUploadIndexFromUpload(state.uploads, upload)
      if (i > -1) {
        state.uploads = [
          ...state.uploads.slice(0, i),
          ...state.uploads.slice(i + 1),
        ]
      }
      return { ...state, uploads: [...state.uploads] }
    }
    case UPDATE_UPLOAD: {
      let uploads = []
      const { upload } = action.payload as { upload: IUploadBase }
      const i = findUploadIndexFromUpload(state.uploads, upload)
      if (i > -1) {
        upload.message = getMessage(upload.state, upload.type, upload.name)
        uploads = state.uploads.map((u, j) =>
          j === i ? { ...u, ...upload } : u
        )
      } else {
        uploads = state.uploads
      }
      return { ...state, uploads }
    }
    case UPLOAD_SUCCESS: {
      const { upload } = action.payload as { upload: IUploadBase }
      const i = findUploadIndexFromUpload(state.uploads, upload)
      let uploads = []
      if (i > -1) {
        upload.message = getMessage(upload.state, upload.type, upload.name)
        uploads = state.uploads.map((u, j) =>
          j === i
            ? { ...upload }
            : u
        )
      } else {
        uploads = state.uploads
      }
      return { ...state, uploads }
    }
    case UPLOAD_MEDIA_SUCCESS: {
      const { mediaupload } = action.payload as { mediaupload: IMediaUpload }
      const i = findUploadIndexFromMediaUpload(state.uploads, mediaupload)
      let uploads = []
      if (i > -1) {
        uploads = state.uploads.map((u: IUploadBase, j: number) => {
          if (j === i) {
            const message = getMessage(mediaupload.state, u.type, u.name)
            return {
              ...u,
              media: mediaupload,
              progress: mediaupload.progress,
              state: mediaupload.state,
              message
            }
          } else {
            return u
          }
        })
      } else {
        uploads = state.uploads
      }
      return { ...state, uploads }
    }
    case UPDATE_MEDIA_UPLOAD: {
      let uploads = []
      const { mediaupload } = action.payload as { mediaupload: IMediaUpload }
      const i = findUploadIndexFromMediaUpload(state.uploads, mediaupload)
      if (i > -1) {
        uploads = state.uploads.map((u: IUploadBase, j: number) => {
          if (j === i) {
            const message = getMessage(mediaupload.state, u.type, u.name)
            return {
              ...u,
              media: mediaupload,
              progress: mediaupload.progress,
              state: mediaupload.state,
              message
            }
          } else {
            return u
          }
        })
      } else {
        uploads = state.uploads
      }
      return { ...state, uploads }
    }
    case REMOVE_MEDIA_UPLOAD: {
      const { mediaupload } = action.payload as { mediaupload: IMediaUpload }
      const i = findUploadIndexFromMediaUpload(state.uploads, mediaupload)
      if (i > -1) {
        state.uploads = [
          ...state.uploads.slice(0, i),
          ...state.uploads.slice(i + 1),
        ]
      }
      return { ...state, uploads: [...state.uploads] }
    }

    default:
      return state
  }
}

export function getMessage(state: UploadState, type: string, name: string): string {
  switch (state) {
    case UploadState.Completed:
      return "Upload completed"
    case UploadState.Starting:
      return `Uploading ${type} ${name}`
    case UploadState.Processing:
      return `Processing ${type} ${name}`
    case UploadState.Error:
      return `Error uploading ${type} ${name}`
  }
}

export function findUploadIndexFromMediaUpload(uploads: IUploadBase[], upload: IMediaUpload): number {
  const i = uploads.findIndex(
    u => {
      return upload.href.indexOf(u.url) > -1 || (upload.src && upload.src?.href.indexOf(u.url) > -1)
    }
  )
  return i
}

export function findUploadIndexFromUpload(uploads: IUploadBase[], upload: IUploadBase): number {
  const i = uploads.findIndex(u => upload.url.indexOf(u.url) > -1)
  return i
}

export default media
