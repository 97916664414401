import { io } from 'socket.io-client'
import { updateInForm } from '../actions/media.actions'
import { REMOVE_MEDIA_UPLOAD, UPDATE_UPLOAD, UPLOAD_MEDIA_SUCCESS } from '../constants/media.constants'
import { store } from '../store/store'

const socket = io(import.meta.env.VITE_API_WSS_URI + '/mediaupload')

socket.on('connect', () => {
  if (!import.meta.env.PROD) console.info('Socket connected')
})
socket.on('connect_error', () => {
  console.error('Error connecting to socket')
})
socket.on('sync', (data) => {
  if (!import.meta.env.PROD) console.log(data)
  const mediaupload = data as IMediaUpload
  if (data.state === 'completed') {
    // acknowledge the upload as having succeeded
    store.dispatch({
      type: UPLOAD_MEDIA_SUCCESS,
      payload: { mediaupload },
    })
    updateInForm(mediaupload)
    setTimeout(
      () =>
        store.dispatch({
          type: REMOVE_MEDIA_UPLOAD,
          payload: { mediaupload },
        }),
      5000
    )
  }
})
socket.on('error', (data) => {
  console.log(data)
  const mediaupload = data as IMediaUpload
  updateInForm(mediaupload)
  store.dispatch({
    type: UPDATE_UPLOAD,
    payload: { mediaupload }
  })
})


export { socket }
