import { createSlice } from '@reduxjs/toolkit/react'
import { DEFAULT_CAPABILITIES } from '../constants'
import { findUploadIndexFromMediaUpload, findUploadIndexFromUpload, getMessage } from '../reducers/media.reducers'

const initialState: IProfileState & IMapState & IWalkState & IUserState & IMediaState & {
  lastFetchedAppConfig: string
} = {
  lastFetchedAppConfig: new Date().toISOString(),
  gettingCollectionsForProfile: false,
  gotCollectionsForProfile: false,
  gettingCollectionsForProfileError: false,
  profileCollections: [],
  activateProfileModalOpen: false,
  selectedProfile: undefined,
  viewport: {},
  currentLocation: [0, 0],
  mapVisible: true,
  mapNeedsRefresh: false,
  mapFullscreen: false,
  mapLayer: -1,
  selectedEcho: -1,
  selectedTrajectory: -1,
  echoToDelete: -1,
  hiddenEchoSlugs: [],
  hoveringEchoSlug: undefined,
  params: {
    limit: 100,
    skip: 0,
  },
  count: 0,
  createCollectionModalOpen: false,
  deleteCollectionModalOpen: false,
  selectedCollectionForDeletion: '',
  moveEchoesModalOpen: false,
  quickProfileModalOpen: false,
  collectionEditTab: 'echoes',
  echoFilterText: '',
  importGpxKMLGeoJsonModalOpen: false,
  importedGpxKmlGeoJson: undefined,
  lang: 'en',
  capabilities: DEFAULT_CAPABILITIES,
  isAdmin: false,
  walkSubscriptionType: 'none',
  elementAdvancedOptionsOpen: ''
}

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    openActivateProfileModal(state, action) {
      state.activateProfileModalOpen = true
      state.selectedProfile = action.payload
    },
    closeActivateProfileModal(state) {
      state.activateProfileModalOpen = false
      state.selectedProfile = undefined
    },
    changeMapViewport(state, action) {
      state.viewport = action.payload
    },
    setUserGeolocation(state, action) {
      state.currentLocation = action.payload
    },
    toggleMapVisible(state) {
      state.mapNeedsRefresh = !state.mapVisible
      state.mapVisible = !state.mapVisible,
        state.mapFullscreen = state.mapVisible ? false : state.mapFullscreen
    },
    toggleMapFullscreen(state) {
      state.mapNeedsRefresh = true
      state.mapFullscreen = !state.mapFullscreen
    },
    selectMapLayer(state, action) {
      state.mapNeedsRefresh = true
      state.mapLayer = action.payload
    },
    selectEcho(state, action) {
      state.selectedEcho = action.payload
    },
    deselectEcho(state) {
      state.selectedEcho = -1
    },
    toggleHideEchoSlug(state, action) {
      if (action.payload) {
        const indexOfSlug = state.hiddenEchoSlugs.indexOf(action.payload)
        if (indexOfSlug > -1) {
          // remove
          state.hiddenEchoSlugs.splice(indexOfSlug, 1)
        } else {
          // add
          state.hiddenEchoSlugs.push(action.payload)
        }
      }
    },
    hoverEchoSlug(state, action) {
      state.hoveringEchoSlug = action.payload
    },
    selectLine(state, action) {
      state.selectedTrajectory = action.payload
    },
    deselectLine(state) {
      state.selectedTrajectory = -1
    },
    setDeleteCollectionModalOpen(state, action) {
      state.deleteCollectionModalOpen = action.payload
    },
    setCreateCollectionModalOpen(state, action) {
      state.createCollectionModalOpen = action.payload
    },
    resetCollectionModals(state) {
      state.deleteCollectionModalOpen = false
      state.echoToDelete = -1
      state.createCollectionModalOpen = false
      state.quickProfileModalOpen = false
    },
    setMoveEchoesModalOpen(state, action) {
      state.moveEchoesModalOpen = action.payload
    },
    setQuickProfileModal(state, action) {
      state.quickProfileModalOpen = action.payload
    },
    openEchoDeleteModal(state, action) {
      state.echoToDelete = action.payload
    },
    closeEchoDeleteModal(state) {
      state.echoToDelete = -1
    },
    setImportGpxKmlGeoJsonModalOpen(state, action) {
      state.importGpxKMLGeoJsonModalOpen = action.payload
    },
    setImportedGpxKmlGeojson(state, action) {
      state.importedGpxKmlGeoJson = action.payload
    },
    setEchoFilterText(state, action) {
      state.echoFilterText = action.payload
    },
    setLang(state, action) {
      state.lang = action.payload
    },
    setUserCapabilities(state, action) {
      state.capabilities = action.payload
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload
    },
    setWalkSubscriptionType(state, action) {
      state.walkSubscriptionType = action.payload
    },
    toggleElementAdvancedOptions(state) {
      state.elementAdvancedOptionsOpen = state.elementAdvancedOptionsOpen ? '' : 'elementAdvancedOptions'
    }
  }
})

export const {
  openActivateProfileModal,
  closeActivateProfileModal,
  changeMapViewport,
  setUserGeolocation,
  toggleMapVisible,
  toggleMapFullscreen,
  selectMapLayer,
  selectEcho,
  deselectEcho,
  toggleHideEchoSlug,
  hoverEchoSlug,
  selectLine,
  deselectLine,
  setDeleteCollectionModalOpen,
  setCreateCollectionModalOpen,
  resetCollectionModals,
  setMoveEchoesModalOpen,
  setQuickProfileModal,
  openEchoDeleteModal,
  closeEchoDeleteModal,
  setImportGpxKmlGeoJsonModalOpen,
  setImportedGpxKmlGeojson,
  setEchoFilterText,
  setUserCapabilities,
  setIsAdmin,
  setWalkSubscriptionType,
  toggleElementAdvancedOptions
} = creatorSlice.actions

export default creatorSlice.reducer
